<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import CheckboxLabel from '@/Components/CheckboxLabel.vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm, usePage } from '@inertiajs/vue3';
import LoginLink from '@/../../vendor/spatie/laravel-login-link/resources/js/login-link.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import ArrowButton from '@/Components/ArrowButton.vue';

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const environment = usePage().props.config.environment;

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <Head :title="$t('auth.log_in')" />

    <AuthLayout>
        <form
            class="flex flex-col justify-between w-full h-full"
            @submit.prevent="submit"
        >
            <div class="flex flex-col justify-center flex-grow mb-4 space-y-4">
                <h1 class="heading-7 text-primary">
                    {{ $t('auth.log_in') }}
                </h1>

                <InputLabel :error="form.errors.email">
                    <TextInput
                        v-model="form.email"
                        name="email"
                        type="email"
                        autofocus
                        autocomplete="username"
                        :placeholder="$t('auth.email')"
                        :has-error="!!form.errors.email"
                        class="bg-white"
                    />
                </InputLabel>

                <InputLabel :error="form.errors.password">
                    <TextInput
                        v-model="form.password"
                        name="password"
                        type="password"
                        autocomplete="current-password"
                        :placeholder="$t('auth.password')"
                        :has-error="!!form.errors.password"
                        class="bg-white"
                    />
                </InputLabel>

                <div class="flex flex-wrap items-center justify-between w-full">
                    <CheckboxLabel
                        :error="form.errors.remember"
                        label-classes="text-primary"
                    >
                        <template #label>
                            {{ $t('auth.remember_me') }}
                        </template>
                        <Checkbox
                            v-model:checked="form.remember"
                            name="remember"
                            class="bg-white rounded checkbox-primary checkbox-sm"
                            :has-error="!!form.errors.remember"
                        />
                    </CheckboxLabel>

                    <Link
                        v-if="canResetPassword"
                        :href="route('password.request')"
                        class="block font-mono text-sm link link-primary"
                    >
                        {{ $t('auth.forgot_password') }}?
                    </Link>
                </div>

                <div v-if="environment === 'local'" class="mt-16 text-primary">
                    <LoginLink
                        email="admin@dotbite.at"
                        label="Login as admin@dotbite.at"
                    />
                    <LoginLink
                        email="user@dotbite.at"
                        label="Login as user@dotbite.at"
                    />
                </div>

            </div>

            <div>
                <div class="text-primary mb-3">
                    {{ $t('auth.no_account_yet') }}
                    <Link :href="route('register')" class="link">
                        {{ $t('auth.register_now') }}!
                    </Link>
                </div>
                <ArrowButton class="btn-block">
                    {{ $t('auth.login') }}
                </ArrowButton>
            </div>
        </form>

        <template #footer>
            <div class="md:self-end">
                <Link
                    :href="route('home')"
                    class="text-white border-white btn btn-outline btn-sm hover:btn-primary hover:!border-white"
                >
                    {{ $t('auth.go_to_start') }}
                </Link>
            </div>
        </template>
    </AuthLayout>
</template>
